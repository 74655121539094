@import "typography";

.pulse-table-header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.pulse-table-header-item {
  font-family: $header-font;
  font-size: 0.95rem;
  color: #858C91;
  font-weight: 400;
  min-width: 170px !important;
  width: 12.5%;
}

.pulse-table-row-item {
  font-family: $body-font;
  font-size: 1rem;
  width: auto;
}

.pulse-table-row {
  background-color: #fff;
  border-bottom: 1px solid #eee;

  &:nth-child(2n+1) {
    background-color: #fff;
  }

  &:last-child {
    border: none;
  }
}
@import "colours";
@import "variables";

.pack-base-status {
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: flex-start;
  border-radius: 15px;
}
.pack-base-status.no-drugs {
  background-color: $mc-blue-01;
  border: 1px solid $mc-blue-01;
}

.pack-base-status.expiring-in-week {
  background-color: $mc-red-01;
  border: 1px solid $mc-red-01;
  transition: background-color ease-in-out $animation-duration;

  &:hover {
    background-color: darken($mc-red-01, 10%);
    border: 1px solid darken($mc-red-01, 10%);
  }
}

.pack-base-status.expiring-in-month {
  background-color: $mc-yellow-01;
  border: 1px solid $mc-yellow-01;
  transition: background-color ease-in-out $animation-duration;

  &:hover {
    background-color: $mc-yellow-hover;
    border: 1px solid $mc-yellow-hover;
  }
}

.pack-base-status.no-action-needed {
  background-color: $mc-green-01;
  border: 1px solid $mc-green-01;
  transition: background-color ease-in-out $animation-duration;

  &:hover {
    background-color: darken($mc-green-01, 10%);
    border: 1px solid darken($mc-green-01, 10%);
  }
}

.pack-base-status.no-drugs {
  background-color: $mc-grey-01;
  border: 1px solid $mc-grey-01;
  transition: background-color ease-in-out $animation-duration;

  &:hover {
    background-color: darken($mc-grey-01, 10%);
    border: 1px solid darken($mc-grey-01, 10%);
  }
}

.report-button {
    padding-right: 0 !important;
    margin-bottom: 1rem;
  
    &:nth-child(odd) {
      padding-left: 0 !important;
    }
  
    button {
      border-radius: 0.5rem;
    }
  }
  
@import "colours";
@import "typography";
@import "variables";

.toggle-wrapper-lg {
  padding: 0 15px !important;
}

.toggle-wrapper-sm {
  width: 65px !important;
}

.toggle-control {
  position: relative;
  padding: 3px 0;
  display: flex;
  align-items: center;
}

input[type='checkbox'].dmcheck {
  width: 75px;
  height: 32px;
  border-radius: 15px;
  background-color: darken($mc-white-01, 10%);
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 44px;
    //background: $mc-yellow-01;
    background: #3d5368;
    border: 1px solid #3d5368;
  }

  &:focus-visible {
    outline: solid 2px $mc-white-01;
  }

  & + label {
    border: 1px solid $mc-white-01;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 1px;
    background: $mc-blue-01;
  }
}


input[type='checkbox'].dmcheck-sm {
  width: 60px;
  height: 17px;
  background-color: darken($mc-white-01, 10%);
  border-radius: 8.5px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 43px;
    background: $mc-yellow-01;
  }

  &:focus-visible {
    outline: solid 2px $mc-white-01;
  }

  & + label {
    border: 1px solid $mc-white-01;
    display: inline-block;
    width: 15px;
    height: 15px;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 1px;
    background: $mc-blue-01;
    border-radius: 50%;
  }
}

input[type='checkbox'].dmcheck:disabled,
input[type='checkbox'].dmcheck-sm:disabled {
  &:checked + label {
    background: $mc-yellow-disabled;
  }

  & + label {
    background: $mc-dark-blue-disabled
  }
}

input[type=checkbox].dmcheck {
  background-color: #fff;
  border: 1px solid #e6e6e6;
}

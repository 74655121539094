@import "colours";
@import "typography";
@import "variables";

.pulse-tab {
  background-color: transparent;
  padding: 5px 15px;
  color: $mc-blue-01;
  border: 1px solid transparent;
  cursor: pointer;
  height: 36px;
  font-family: $header-font;
  transition: background-color ease-in-out $animation-duration;
  &:hover {
    background-color: $mc-grey-04;
  }
}

.pulse-tab-content {
  padding: 15px 0;
  background-color: transparent;
  min-height: 550px;
  margin: 0 0 10px 0;
}

.pulse-tab.active {
  background-color: $mc-yellow-01;
  color: $mc-blue-01;
  border: 1px solid $mc-yellow-01;
}

.pulse-nav-tabs {
  padding-top: 10px;
}

.pulse-add-tab-button {
  background: none;
  border: none;
}

.report-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
  
    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    }
  
    .report-button {
      flex-grow: 1;
      margin: 0;
  
      button {
        margin: 0;
      }
    }
  }
@import "colours";
@import "typography";
@import "variables";

.drug-expiry-week {
  color: $mc-red-01;
  transition: color ease-in-out $animation-duration;
  &:hover {
    color: darken($mc-red-01, 10%);
    cursor: pointer;
  }
}

.drug-expiry-month {
  color: $mc-yellow-01;
  transition: color ease-in-out $animation-duration;
  &:hover {
    color: $mc-yellow-hover;
    cursor: pointer;
  }
}

.low-stock-yellow.low-stock-lg,
.low-stock-red.low-stock-lg,
.low-stock-green.low-stock-lg {
  height: 0.75rem;
  width: 0.75rem;
}

.low-stock-yellow.low-stock-sm,
.low-stock-red.low-stock-sm,
.low-stock-green.low-stock-sm {
  height: 0.75rem;
  width: 0.75rem;
  margin-left: 0.4rem;
}

.low-stock-yellow,
.low-stock-red,
.low-stock-green {
  //margin: 2px 0 0 3px;
  border-radius: 50%;
  color: $mc-white-01;
  margin: 0.3rem 0 0 0.4rem;
  height: 0.75rem;
  width: 0.75rem;

  &:hover {
    cursor: pointer;
  }
}

.low-stock-red {
  //background-color: $mc-red-01;
  background-color: #C70A0A;
  transition: background-color ease-in-out $animation-duration;
}

.low-stock-yellow {
  //background-color: $mc-yellow-01;
  background-color: #E0A309;
  transition: background-color ease-in-out $animation-duration;
}

.low-stock-green {
  //background-color: $mc-green-01;
  background-color: #2a8a41;
  transition: background-color ease-in-out $animation-duration;
}

@import "../core/colours";

// Container for all drug widgets, lining them up in a row
div.drug-widgets {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding: 1.5rem 2rem 0 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    gap: 0.9rem;
  }
}

div.drug-widget {
  background-color: #edf9f0;
  color: $c1-content;
  border-radius: 10px;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;

  div.drug-widget-icon {
    color: #2a8a41;
  }

  div.drug-widget-icon-wrapper {
    display: flex;
    gap: 0.7rem;
    width: fit-content;
    //flex-direction: column;
    //align-items: center;
  }

  // Rounded background for icons that don't have a round background
  div.drug-widget-icon-rounded {
    background-color: red;
    color: white;
    border-radius: 999px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p.drug-widget-count {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0;
    margin: 0.2rem 0 0;
  }

  a.drug-widget-description {
    color: $c1-content;
    font-weight: 500;
    font-size: 0.9rem;
    text-decoration: underline !important;
  }

  // Overrides for non-green widgets

  &.drug-widget-expired {
    background-color: #feefef;

    div.drug-widget-icon {
      color: #c70a0a;
    }
  }

  &.drug-widget-30 {
    background-color: #fafafa;

    div.drug-widget-icon-rounded {
      background-color: #858c91;
    }
  }

  &.drug-widget-7 {
    background-color: #fffbf2;

    div.drug-widget-icon-rounded {
      background-color: #e0a309;
    }
  }
}
@import "colours";
@import "variables";
@import "typography";

.report-button {
  padding-right: 0 !important;

  &:nth-child(odd) {
    padding-left: 0 !important;
  }
}


.date-switcher-button {
  height: 42px;
  width: 42px;
  //background-color: $mc-yellow-01;
  background-color: #1a2a35;
  //color: $mc-blue-01;
  color: #fff;
  border: 1px solid $mc-yellow-01;
  font-weight: 400 !important;

  &:hover {
    transition: all ease $animation-duration;
    //background-color: $mc-yellow-hover;
    background-color: #405368;
    //border: 1px solid $mc-yellow-hover;
    cursor: pointer;
  }
}

.report-button {
  padding-right: 0 !important;

  &:nth-child(odd) {
    padding-left: 0 !important;
  }
}

.btn-base-xl,
.btn-base-lg,
.btn-base {
  color: $mc-white-01;
  text-align: center;
  font-family: $header-font;
  font-size: 1rem;
  text-decoration: none;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-weight: 500 !important;

  transition: background-color $animation-duration ease;
  -webkit-transition: background-color $animation-duration ease;
}

.btn-base-xl {
  font-size: 1rem;
}

.btn-base-lg {
  font-size: 1rem;
  margin-right: 1rem;
}

.btn-base {
  font-size: 1rem;
}

.btn-base-xl.rounded-corner {
  border-radius: 0.5rem;
}

.btn-base-lg.rounded-corner {
  border-radius: 0.5rem;
}
.btn-base.rounded-corner {
  border-radius: 12.5px;
}

.btn-base-xl.scaled-font,
.btn-base-lg.scaled-font,
.btn-base.scaled-font {
  font-size: clamp(0.6em, unquote("0.3vw + 0.45rem"), 0.8em) !important;
}

.btn-base.green-btn,
.btn-base.light-blue-btn,
.btn-base.orange-btn,
.btn-base.red-btn,
.btn-base.yellow-btn,
.btn-base.dark-blue-btn,
.btn-base-lg.green-btn-lg,
.btn-base-lg.light-blue-btn-lg,
.btn-base-lg.orange-btn-lg,
.btn-base-lg.red-btn-lg,
.btn-base-lg.yellow-btn-lg,
.btn-base-lg.dark-blue-btn-lg,
.btn-base-xl.green-btn-xl,
.btn-base-xl.light-blue-btn-xl,
.btn-base-xl.orange-btn-xl,
.btn-base-xl.red-btn-xl,
.btn-base-xl.yellow-btn-xl,
.btn-base-xl.dark-blue-btn-xl {
  width: auto;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: default;
    transition: all $animation-duration ease;
    -webkit-transition: all $animation-duration ease;
  }
}

.btn-base.green-btn,
.btn-base-lg.green-btn-lg,
.btn-base-xl.green-btn-xl {
  background-color: $mc-green-01;
  border: 1px solid $mc-green-01;

  &:hover {
    background-color: $mc-green-hover;
    border: 1px solid $mc-green-hover;
  }

  &:disabled {
    background-color: $mc-green-disabled;
    border: 1px solid $mc-green-disabled;
  }
}

.btn-base.light-blue-btn,
.btn-base-lg.light-blue-btn-lg,
.btn-base-xl.light-blue-btn-xl {
  background-color: $mc-blue-04;
  border: 1px solid $mc-blue-04;

  &:hover {
    background-color: $mc-light-blue-hover;
    border: 1px solid $mc-light-blue-hover;
  }

  &:disabled {
    background-color: $mc-light-blue-disabled;
    border: 1px solid $mc-light-blue-disabled;
  }
}

.btn-base.orange-btn,
.btn-base-lg.orange-btn-lg,
.btn-base-xl.orange-btn-xl {
  background-color: $mc-orange-01;
  border: 1px solid $mc-orange-01;

  &:hover {
    background-color: $mc-orange-hover;
    border: 1px solid $mc-orange-hover;
  }

  &:disabled {
    background-color: $mc-orange-disabled;
    border: 1px solid $mc-orange-disabled;
  }
}

.btn-base.red-btn,
.btn-base-lg.red-btn-lg,
.btn-base-xl.red-btn-xl{
  background-color: $mc-red-01;
  border: 1px solid $mc-red-01;

  &:hover {
    background-color: $mc-red-hover;
    border: 1px solid $mc-red-hover;
  }

  &:disabled {
    background-color: $mc-red-disabled;
    border: 1px solid $mc-red-disabled;
  }
}

.btn-base.yellow-btn,
.btn-base-lg.yellow-btn-lg,
.btn-base-xl.yellow-btn-xl {
  color: #fff;
  background-color: #3d5368;
  border: 1px solid #3d5368;


  &:hover {
    //background-color: $mc-yellow-hover;
    border: 1px solid $mc-yellow-hover;
    background-color: #3d5368;
    border: 1px solid #3d5368;
  }

  &:disabled {
    //background-color: $mc-yellow-disabled;
    //border: 1px solid $mc-yellow-disabled;
    background-color: #3d5368;
    border: 1px solid #3d5368;
  }
}

.btn-base.dark-blue-btn,
.btn-base-lg.dark-blue-btn-lg,
.btn-base-xl.dark-blue-btn-xl {
  background-color: #1a2a35;
  border: 1px solid #1a2a35;

  &:hover {
    background-color: #3d5368;
    border: 1px solid #3d5368;
  }

  &:disabled {
    background-color: $mc-dark-blue-disabled;
    border: 1px solid $mc-dark-blue-disabled;
  }
}

.report-button .dark-blue-btn-xl {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.report-button .btn-base-xl.yellow-btn-xl {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
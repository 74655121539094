.pulse-modal-content-xl > .pulse-modal-body {
  min-height: 345px !important;
}

.pulse-modal-body{
  padding: 1rem;
}

.pulse-modal-body .col{
  padding: 0;
}

.pulse-modal-body .row {
  //background-color: #ebeef2;
}

.modal-background {
  margin: 0.65rem;
}

.pulse-modal-header {
  padding: 0.7rem;
}

.pulse-modal-footer {
  padding: 1rem;
  //border-top: 1px solid #eee;
}

.pulse-modal-title {
  font-size: 1.3rem;
}
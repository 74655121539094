@import "colours";

.expiry-indicator-base {
  float: left;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 3px 3px 0;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}
.expiry-indicator-base.no-action-needed {
  background-color: $mc-green-01;
}

.expiry-indicator-base.expires-in-month {
  background-color: $mc-yellow-01;
}

.expiry-indicator-base.expires-in-week {
  background-color: $mc-red-01;
}

.expiry-indicator-base.empty {
  background-color: $mc-blue-01;
}
